import { EnergyStopResponse } from '@app/services/energy-stop/types/energy-stop-response';
import { EnergyStopSuppliersStatus } from '@app/services/energy-stop/enums/energy-stop-suppliers-status';
import { EnergyStopActiveServices } from '@app/services/energy-stop/enums/energy-stop-active-services';
import { ServiceStatus } from '@app/services/enums/service-status.enum';

export class EnergyStop {
    states: {
        suppliersStatus: EnergyStopSuppliersStatus;
        meterComplete: boolean;
    };
    energyStopActiveServices: EnergyStopActiveServices;
    supplierElectricity?: string;
    supplierGas?: string;
    status?: ServiceStatus;
    active?: boolean;

    constructor(partial: EnergyStopResponse) {
        this.states = partial.states;
        this.energyStopActiveServices = partial.energyStopActiveServices;
        this.supplierElectricity = partial.supplierElectricity;
        this.supplierGas = partial.supplierGas;
        this.status = partial.status;
        this.active = partial.active;
    }

    public isActiveAndSubmitted(): boolean {
        return !!this.active && this.status === ServiceStatus.Submitted;
    }

    public isSameSupplier(): boolean {
        return (
            (this.supplierElectricity && this.supplierGas && this.supplierElectricity === this.supplierGas) ||
            (!this.supplierElectricity && !this.supplierGas)
        )
    }
}
